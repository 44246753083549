@import "../../styles/variables";

.Footer {
  margin: 15rem 0 0 0;
  padding: 10rem 0 17rem 0;
  background-color: #1A181B;

  @media (max-width: $lg) {
    padding: 5rem 0 8rem;
  }

  @media (max-width: 720px) {
    padding: 4rem 0 6rem;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $lg) {
      flex-direction: column;
    }
  }
  
  .Menu {
    @media (max-width: 720px) {
      flex-direction: column;
    }
  }

  .MenuItem {
    @media (max-width: $xxl) {
      font-size: 1.6rem;
      margin: 0 1.5rem;
    }

    @media (max-width: $xl) {
      font-size: 1.4rem;
      margin: 0 1rem;
    }

    @media (max-width: $md) {
      font-size: 1.2rem;
    }

    @media (max-width: 720px) {
      font-size: 2rem;
      margin: 0 0 2.8rem 0;
    }
  }

  &__logo {
    @media (max-width: $lg) {
      order: 10;
      margin-top: 6rem;
    }

    @media (max-width: $md) {
      order: 10;
      margin-top: 4rem;
    }

    @media (max-width: 720px) {
      margin-top: 8.2rem;
    }

    img {
      @media (max-width: $xxl) {
        height: 2.8rem;
      }
    }
  }
}