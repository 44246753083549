@import "../../styles/mixins";
@import "../../styles/variables";

.PrivacyPolicy {
  line-height: 1.3;
  font-size: 2.2rem;

  h1 {
    margin-bottom: 4rem;
    font-size: 6.4rem;
  }

  h2 {
    font-size: 3.2rem;
  }

  a {
    @include link($linkActiveColor, $btnColorActive);
  }
}