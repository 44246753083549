@import "../../../styles/variables";

.SimplAdmin {
  position: relative;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Section__title {
    color: #fff !important;
  }

  &__left {
    max-width: 90rem;
    z-index: 1;

    @media (max-width: $xxl) {
      max-width: 54rem;
      margin-right: 10rem;
    }

    @media (max-width: $md) {
      max-width: 30rem;
      margin-right: 3rem;
    }

    @media (max-width: 720px) {
      max-width: none;
      margin-right: 0;
    }
  }


  &__image {
    @media (max-width: $lg) {
      position: relative;
      right: -8%;
    }

    @media (max-width: 720px) {
      position: absolute;
      right: -5rem;
      top: 50%;
      transform: translateY(-50%);
      height: 98%;

      > img {
        height: 98%;
      }
    }
    
    @media (max-width: $sm) {
      display: none;
    }
  }

  &__imageSm {
    display: none;

    @media (max-width: $sm) {
      display: block;
      position: absolute;
      bottom: -10rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .Section__body {
    margin: 0 0 6.8rem 0;

    @media (max-width: 720px) {
      margin: 0 0 5rem 0;
    }
  }

}

.SimplAdminFeature {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  &__icon {
    display: inline-flex;
    margin: 0 4.6rem 0 0;
    width: 5rem;
    justify-content: center;

    @media (max-width: $xxl) {
      font-size: 3rem;
      margin-right: 2.4rem;
      height: 3rem;
      width: 100%;
      max-width: 3rem;
    }
  }

  &__text {
    color: #fff;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 1.3;

    @media (max-width: $xxl) {
      font-size: 2.4rem;
    }

    @media (max-width: $lg) {
      font-size: 1.8rem;
    }
  }
}