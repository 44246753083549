@import "../../styles/mixins";
@import "../../styles/variables";

.Menu {
  display: flex;
  align-items: center;
  line-height: 1.1;
  font-family: $FontComfortaa;
}

.MenuItem {
  display: block;
  font-size: 2rem;
  color: inherit;
  margin: 0 25px;
  cursor: pointer;

  @include link;
}