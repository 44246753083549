@import "../../../styles/variables";

.Devices {
  display: flex;
  margin: 0 -1.2rem;

  @media(max-width: 720px) {
    flex-wrap: wrap;
    position: relative;;
  }
}

.DevicesItem {
  width: 20%;
  padding: 0 1.2rem;

  @media(max-width: 720px) {
    width: 50%;
    margin-bottom: 1.2rem;
    position: relative;
  }

  @media(max-width: $sm) {
    width: 100%;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35.6rem;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 1rem;
    margin: 0 0 4.5rem 0;

    @media (max-width: $xxl) {
      height: 23rem;
    }

    @media (max-width: $xl) {
      height: 19rem;
      margin: 0 0 1.4rem 0;
    }

    @media (max-width: $lg) {
      height: 12.6rem;
      margin: 0 0 1.1rem 0;
    }

    @media (max-width: 720px) {
      position: relative;
      height: 25rem;
      margin: 0;
      padding-bottom: 5rem;
      padding-top: 2rem;
    }


    img {
      max-height: 26rem;

      @media (max-width: $xxl) {
        max-height: 16rem;
      }

      @media (max-width: $xl) {
        max-height: 11rem;
      }

      @media (max-width: $lg) {
        max-height: 7rem;
      }

      @media (max-width: $md) {
        max-height: 13rem;
      }
    }
  }

  &__text {
    font-size: 3rem;
    line-height: 1.1;

    @media (max-width: $xxl) {
      font-size: 2rem;
    }

    @media (max-width: $xl) {
      font-size: 1.8rem;
    }

    @media (max-width: $lg) {
      font-size: 1.3rem;
    }

    @media (max-width: 720px) {
      font-size: 2rem;
      position: absolute;
      z-index: 1;
      padding: 0 5rem;
      text-align: center;
      left: 0;
      right: 0;
      bottom: 2rem;
    }
  }
}