@import "src/styles/variables";

.Header {
  &.container {
    display: flex;
    align-items: center;
    padding-top: 6rem;
    padding-bottom: 6rem;
    margin-bottom: 6rem;

    &.isOpen {
      @media (max-width: $lg) {
        padding: 2rem 2rem 4.8rem 2rem;
        margin: 0;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $bodyColor;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1001;

        .Header__menuMob {
          display: block;
          flex: 1 1 100%;
          width: 100%;
          position: relative;
        }

        .Menu {
          position: absolute;
          flex-direction: column;
          flex: 1;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
        }

        .MenuItem {
          margin-bottom: 2.4rem;
          font-size: 1.8rem;
          line-height: 1.3;
        }

        .Header__btn {
          margin-top: auto;
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        .Header__mobTrigger {
          position: absolute;
          top: 2rem;
          right: 2rem;
        }
      }
    }

    @media (max-width: $xl) {
      margin-bottom: 2rem;
    }

    @media (max-width: $lg) {
      margin-bottom: 0rem;
      padding-bottom: 4rem;
    }

    @media (max-width: $md) {
      padding-top: 2rem;
      padding-bottom: 3rem;
    }
  }

  &__logo {
    height: 4rem;
    width: auto;

    @media (max-width: $xxl) {
      height: 2.8rem;
    }

    @media (max-width: $xl) {
      height: 3.5rem;
    }

    @media (max-width: $md) {
      height: 2.3rem;
    }

  }

  &__menu {
    margin: 0 auto;

    @media ($lg) {
      background-color: $bodyColor;
    }

    .MenuItem {
      @media (max-width: $xxl) {
        font-size: 1.4rem;
        margin: 0 15px;
      }

      @media ($lg) {
        
      }
    }
  }

  &__btn {
    @media (max-width: $xxl) {
      font-size: 1.8rem;
      padding: 1rem 1.5rem;
    }

    @media (max-width: $lg){
      display: none;
    }
  }

  &__menuMob {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $lg) {
      display: none;
    }
  }

  &__mobTrigger {
    display: none;

    @media (max-width: $lg) {
      margin: 0 0 0 auto;
      display: block;
      border: 0;
      background-color: transparent;
    }

    @media (max-width: $md) {
      img {
        height: 1.4rem;
      }
    }
  }
}