// Colors

$bodyColor: #E5E5E5;
$mainFontColor: #7C8287;
$btnColor: rgb(21, 195, 206);
$btnColorHover: rgba(21, 195, 206, 0.8);
$btnColorFocus: rgba(21, 195, 206, 0.6);
$btnColorActive: rgba(21, 195, 206, 0.4);
$linkActiveColor: #15C3CE;

// Breakpoints
$xxl: 1640px;
$xl: 1160px;
$lg: 1024px;
$md: 992px;
$sm: 576px;

$FontComfortaa: 'Comfortaa', cursive;
$FontMontserrat: 'Montserrat', sans-serif;

// Layout
$containerBorders: 2rem;

@media (max-width: $sm){
  $containerBorders: 1.243m;
} 
