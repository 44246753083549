@import "variables";

@mixin link($color: $mainFontColor, $activeColor: $linkActiveColor) {
  text-decoration: none;
  color: $color;
  cursor: pointer;

  &:visited{
    color: $color;
  }

  &:focus,
  &:active,
  &:hover {
    color: $activeColor;
  }
}