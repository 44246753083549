@import "../../../styles/variables";

.Contacts {
  display: flex;
  background-color: #fff;
  padding: 10rem;
  border-radius: 1rem;

  @media (max-width: $lg) {
    padding: 5rem 9rem;
  }

  @media (max-width: 720px) {
    padding: 5rem 9rem;
  }

  @media (max-width: 660px) {
    padding: 4rem 2rem;
    display: block;
  }

  &__text {
    max-width: 48rem;
    margin: 0 10rem 0 0;

    @media (max-width: $xxl) {
      max-width: 38rem;
      margin: 0 5rem 0 0;
    }

    @media (max-width: $lg) {
      max-width: 25rem;
    }

    @media (max-width: 720px) {
      max-width: 21rem;
      margin: 0 2rem 0 0;
    }

    @media (max-width: 660px) {
      max-width: none;
      margin-bottom: 2rem;
    }
  }

  &__disclaimer {
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.3;

    @media (max-width: $xxl) {
      font-size: 2.4rem;
    }

    @media (max-width: 660px) {
      font-size: 1.8rem;
    }
  }
}

.ContactsForm {
  display: block;
  width: 100%;
  padding: 0 30rem 0 0;

  @media (max-width: $xxl) {
    padding: 0;
  }
}

.FormInput {
  display: block;
  width: 100%;
  padding: 1.4rem 1.6rem;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  line-height: 1.3;
  outline: none;
  color: #000;
  border: 1px solid #BDBDBD;
  appearance: none;

  &:hover,
  &:focus {
    border-color: #000;
  }

  &::placeholder {
    color: #BDBDBD;
  }

  &.error {
    color: #EF5454;
    border-color: #EF5454;

    &::placeholder {
      color: #EF5454;
    }
  }
}


textarea.FormInput {
  height: 15rem;
  resize: none;
}