@import "../../../styles/variables";

.HomeDevices {
  display: flex;
  position: relative;

  @media (max-width: 720px) {
    display: block;
  }

  &__text {
    display: flex;
    align-items: center;
    margin: 0 6rem 0 0;
    max-width: 65rem;

    @media (max-width: $xxl) {
      max-width: 48rem;
    }

    @media (max-width: $md) {
      max-width: none;
    }

    @media (max-width: 720px) {
      margin: 0;
    }
  }

  &__title {
    margin: 0 0 4.8rem 0;
    font-weight: 500;
    font-size: 4.8rem;
    line-height: 7rem;
    color: $mainFontColor;
    
    @media (max-width: $xxl) {
      font-size: 3.6rem;
      line-height: 5.5rem;
      margin-bottom: 5rem;
    }

    @media (max-width: $xl) {
      font-size: 2.4rem;
      line-height: 1.3;
    }

    @media (max-width: $xl) {
      margin-bottom: 2rem;
    }
  }

  &__devices {
    @media (max-width:$lg) {
      padding: 5rem 3rem;
      max-width: 40rem;
    }

    @media (max-width: 720px) {
      margin: 0 auto;
      padding: 3rem 0 8rem;
    }
  }

  &__btn {
    @media (max-width: 720px) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

}