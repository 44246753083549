@import "../../styles/variables";
@import "../../styles/normalize";

@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

:root {
  font-size: 62.5%;
}

body {
  color: $mainFontColor;
  font-size: 1.6rem;
  font-family: $FontMontserrat;
  background-color: $bodyColor;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $FontComfortaa;
  color: #1A181B;
}

h2 {
  font-size: 6.4rem;
  line-height: 1.1;
  margin: 0 0 5rem 0;

  @media (max-width: $xxl) {
    font-size: 4.8rem;
  }

  @media (max-width: $lg) {
    font-size: 3.2rem;
    margin-bottom: 5rem;
  }

  @media (max-width: $md) {
    margin-bottom: 2rem;
  }
}

.container {
  margin: 0 auto;
  padding: 0 $containerBorders;
  width: $xxl;

  @media(max-width: $xxl) {
    width: $xl;
  }

  @media(max-width: $xl) {
    width: $lg;
  }

  @media (max-width: $lg) {
    width: $md;
  }

  @media(max-width: $md) {
    width: 100%;
  }
}

.Btn {
  display: inline-block;
  padding: 1.4rem 2.5rem;
  border-radius: 4px;
  text-align: center;
  font-size: 2rem;
  line-height: 1.1;
  color: #FFF;
  background-color: $btnColor;
  text-decoration: none !important;
  border: none;
  outline: none;
  cursor: pointer;

  @media (max-width: $xxl) {
    font-size: 1.8rem;
    padding: 1rem 2rem;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #FFF;
  }

  &:hover,
  &:focus {
    background-color: $btnColorHover;
  }

  &:active {
    background-color: $btnColorActive;
  }

  &:visited {
    background-color: $btnColor;
  }

  &_big {
    font-size: 2.4rem;
    line-height: 1;
    padding: 2rem 9.6rem;

    @media (max-width: $xxl) {
      padding: 1.8rem 5rem;
    }

    @media (max-width: $md) {
      font-size: 1.6rem;
      padding: 1.4rem 3.5rem;
    }
  }

  &_full {
    width: 100%;
    margin: 0;
  }
}

.Section {
  margin: 0 0 15rem 0;

  @media (max-width: $xxl) {
    margin-bottom: 11rem;
  }

  @media (max-width: $lg) {
    margin-bottom: 5rem;
  }

  @media (max-width: $sm) {
    margin-bottom: 4rem;
  }

  &__body {
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.3;

    p:last-child {
      margin: 0;
    }

    @media (max-width: $xxl) {
      font-size: 2.4rem;
    }

    @media (max-width: $lg) {
      font-size: 1.8rem;
    }

    @media (max-width: $lg) {
      font-size: 1.6rem;
    }
  }

  &.Section__columns {
    display: flex;

    > * {
      flex: 1;
    }

    @media (max-width: $lg) {
      display: block;
    }
  }

  &.Section__black {
    overflow: hidden;
    border-radius: 1rem;
    padding: 15rem 0 15rem 7rem;
    background-color: #1A181B;

    @media (max-width: $xxl) {
      padding: 10rem 0 10rem 7rem;
    }

    @media (max-width: $lg) {
      padding: 5rem;
    }

    @media (max-width: $sm) {
      padding-bottom: 15rem;
    }
  }
}

.ImgFluid {
  max-width: 100%;
  height: auto;
}
